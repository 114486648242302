import * as React from "react"
import {
  StyledCheckbox,
  StyledCheckboxLabel,
  Heading,
  Button,
} from "gatsby-interface"
import { AgencyFilters } from "../../pages/agencies"

function Checkbox({ label, name, ...props }: any) {
  return (
    <StyledCheckboxLabel
      css={theme => ({
        display: "flex",
        width: "100%",
        alignItems: "center",
        fontSize: theme.fontSizes[1],
        marginBottom: theme.space[2],
      })}
    >
      <div
        css={{
          // hack for 16x16 checkbox
          transform: "scale(0.8)",
        }}
      >
        <StyledCheckbox name={name} {...props} />
      </div>
      <span
        css={theme => ({
          display: "inline-block",
          marginLeft: theme.space[2],
          // compensate for gi alignment
          marginBottom: -6,
        })}
      >
        {label}
      </span>
    </StyledCheckboxLabel>
  )
}

function CheckboxFilters({
  label,
  name,
  options = [],
  value = [],
  onChange,
}: {
  label: string
  name: string
  options: string[]
  value?: string[]
  onChange: React.Dispatch<React.SetStateAction<AgencyFilters>>
}) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    const next = [...value, e.target.value].filter(
      v => checked || v !== e.target.value
    )
    onChange((filters: AgencyFilters) => ({ ...filters, [name]: next }))
  }

  return (
    <div
      css={theme => ({
        fontSize: theme.fontSizes[1],
        marginBottom: theme.space[8],
      })}
    >
      <div
        css={theme => ({
          fontWeight: theme.fontWeights.bold,
          marginBottom: theme.space[3],
        })}
      >
        {label}
      </div>
      {options.map(option => (
        <div key={option}>
          <Checkbox
            label={option}
            name={name}
            value={option}
            onChange={handleChange}
            checked={value.includes(option)}
          />
        </div>
      ))}
    </div>
  )
}

interface FiltersProps {
  value: AgencyFilters
  onChange: React.Dispatch<React.SetStateAction<AgencyFilters>>
  languages: string[]
}

export default function Filters({
  value = {},
  onChange,
  languages,
}: FiltersProps) {
  const isActive = Object.values(value).some(
    item => item.length && item.length > 0
  )

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
  }

  const resetFilters = (e: React.SyntheticEvent) => {
    onChange({})
  }

  return (
    <form onSubmit={handleSubmit}>
      <div
        css={theme => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: theme.space[6],
          minHeight: theme.space[7],
        })}
      >
        <Heading
          as="h3"
          css={theme => ({
            fontSize: theme.fontSizes[0],
            textTransform: "uppercase",
            letterSpacing: theme.letterSpacings.tracked,
          })}
        >
          Filters
        </Heading>
        {isActive && (
          <Button
            variant="SECONDARY"
            size="S"
            css={theme => ({
              fontWeight: theme.fontWeights.bold,
            })}
            type="reset"
            onClick={resetFilters}
          >
            Clear All
          </Button>
        )}
      </div>
      <CheckboxFilters
        label="Regions"
        name="regions"
        value={value.regions}
        onChange={onChange}
        options={[
          `Africa`,
          `Asia`,
          `Caribbean`,
          `Central America`,
          `Europe`,
          `Middle East`,
          `North America`,
          `Oceania`,
          `South America`,
        ]}
      />
      <CheckboxFilters
        label="Languages"
        name="languages"
        value={value.languages}
        onChange={onChange}
        options={[...languages]}
      />
      <CheckboxFilters
        label="Technical Expertise"
        name="expertises"
        value={value.expertises}
        onChange={onChange}
        options={["eCommerce", "Design", "UI/UX design", "Content"]}
      />
      <CheckboxFilters
        label="Platform Support"
        name="platforms"
        value={value.platforms}
        onChange={onChange}
        options={[
          "Contentful",
          "Contentstack",
          "DatoCMS",
          "Drupal",
          "HubSpot",
          "Hygraph",
          "Kontent",
          "Prismic",
          "Sanity",
          "Segment.io",
          "Shopify",
          "Storyblok",
          "Strapi",
          "WordPress",
        ]}
      />
    </form>
  )
}
